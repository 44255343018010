import { useRef, useCallback, useState, useEffect } from 'react'
import './style.scss';
import Webcam from "react-webcam";
import { RekognitionClient, DetectTextCommand } from "@aws-sdk/client-rekognition"
import { CognitoIdentityClient } from "@aws-sdk/client-cognito-identity";
import { fromCognitoIdentityPool } from "@aws-sdk/credential-provider-cognito-identity";
import Scan from "../Welcome/img/scan.svg"
// import { ReactComponent as Target } from "./img/target.svg"
// import { ReactComponent as TargetChecked } from "./img/target-checked.svg"
import { useGeolocated } from "react-geolocated";
import { places } from "../../data/places"
import Arrow from "../Welcome/img/arrow.svg"
import { useNavigate } from 'react-router-dom'
import { getAccountDetails, useUserDispatch, useUserState } from '../../UserContext';
import { showErrorPopup, translateError } from '../../components/ErrorHandling';
import { sendEvent } from '../../Helpers';
import HelpIcon from "./img/help.svg"

import * as THREE from 'three';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import {DeviceOrientationControls} from '../ARComponent/DeviceOrientationControls';
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment.js';
// import _ from 'lodash'

//eslint-disable-next-line
let inTarget = false
let targetName = ""

const Toast = ({desc}) => {

  return (
    <div className={ desc ? 'toast show' : 'toast' }>
      <p dangerouslySetInnerHTML={{ __html: desc }}></p>
    </div>
  )
}

const Tutorial = ({onClose, payout}) => {
  const [animate, setAnimate] = useState("")
  
  const steps = [
      {
          title: "Herzlichen Glückwunsch!",
          code: "<strong>+" + payout + "p</strong>",
          description: "Udało Ci się ukończyć pierwszy krok, a&nbsp;punkty wpadły na Twoje konto."
      }
  ]

  useEffect(() => {
      setTimeout(() => { setAnimate("animate") }, 800)
  }, [])

  return (
      <div className={ 'bottom-modal tutorial ' + animate }>
          <div className='content'>
              <h3>Herzlichen Glückwunsch!</h3>
              <p>Dies ist dein erster Scan! Wir haben deinem glo+ Konto gutgeschrieben:</p>
              { steps[0].code ? <div className='code' dangerouslySetInnerHTML={{ __html: steps[0].code }}></div> : null }
              <p>Bleib dran für mehr!</p>
          </div>
          {/* <button className='next' onClick={ () => {
              if(step < 1) {
                  setStep(step+1)
              } else {
                  setAnimate("")
                  setTimeout(() => { onClose() }, 500)
              }
          } }><img src={ Arrow } alt=""/></button> */}
          <button className='button' onClick={() => {
            setAnimate("")
            setTimeout(() => { onClose() }, 500)
          }}>Geschafft!</button>
      </div>
  )
}

const Intro = ({onClose, code}) => {
  const navigate = useNavigate()
  const [step, setStep] = useState(0)
  const [animate, setAnimate] = useState("")

  const steps = [
      {
          title: "Kupon!",
          code: "KOD: <strong>" + code + "</strong>",
          description: <>Kupon trafił właśnie na Twoje konto. <br/>Przejdź do <a href={"" + process.env.REACT_APP_GLOPLUS_URL + "/nagrody/odebrane?ageGate=false&token=" + JSON.parse(sessionStorage.getItem('token')).uuid } target="_self" onClick={(e) => {
            e.preventDefault()
            navigate("/skaner", { replace: true })
            document.location.href = process.env.REACT_APP_GLOPLUS_URL + "/nagrody/odebrane?ageGate=false&token=" + JSON.parse(sessionStorage.getItem('token')).uuid
          }}>Moich Nagród</a>.</>
      },
      {
          title: "Wróć po więcej!",
          description: <>Kolejny kupon na mieście pojawi się w następnym tygodniu. <br/><br/>Nie pozwól, aby Ci uciekł.</>
      }
  ]

  useEffect(() => {
      setTimeout(() => { setAnimate("animate") }, 800)
  }, [])

  return (
      <div className={ 'bottom-modal intro ' + animate }>
          <ul className='dots'>
              <li className={step === 0 ? "active" : ""}></li>
              <li className={step === 1 ? "active" : ""}></li>
          </ul>
          <div className='content'>
              <h3 dangerouslySetInnerHTML={{ __html: steps[step].title }}></h3>
              { steps[step].code ? <div className='code' dangerouslySetInnerHTML={{ __html: steps[step].code }}></div> : null }
              <p>{steps[step].description}</p>
          </div>
          <button className='next' onClick={ () => {
            if(step < 1) {
              setStep(step+1)
            } else {
                setAnimate("")
                setTimeout(() => { onClose() }, 500)
            }
          } }><img src={ Arrow } alt=""/></button>
      </div>
  )
}

function App({setDebug}) {
  // ---------------- AR ----------------------
  const refContainer = useRef();
  const refButton = useRef();
  const rendererRef = useRef(null)
  const clockRef = useRef(null)
  const sceneRef = useRef(null)
  const cameraRef = useRef(null)
  const groupRef = useRef(null)
  let controls = useRef(null);
  const mixers = useRef([])
  let currentModels = [];

  // -------------------------------------------

  const dispatch = useUserDispatch()
  const { accountDetails } = useUserState()
  // const { pathname } = useLocation()
  // const firstRun = accountDetails?.zonePlus.showTutorial
  const webcamRef = useRef(null);
  const [image, setImage] = useState("")
  // const [data, setData] = useState([])
  // const [coordStats, showCoordStats] = useState(false)
  const [toastDesc, setToastDesc] = useState("")
  const distance = 0.0012
  const [payout, setPayout] = useState(0)
  const [code, setCode] = useState("")
  const navigate = useNavigate();
  const [hideUI, setHideUI] = useState(false)
  const [help, setHelp] = useState(false)
  const [product, setProduct] = useState("")
  
  const { coords } =
    useGeolocated({
        positionOptions: {
            enableHighAccuracy: true,
        },
        watchPosition: true,
        userDecisionTimeout: 5000,
    });
  const [loading, setLoading] = useState(false)
  const [loadingA, setLoadingA] = useState(false)
  const [cameraPermission, setCameraPermission] = useState("")

  // useEffect(() => {
  //   navigator.permissions.query({ name: 'camera' }).then((permissionStatus) => {
  //       console.log(`camera permission state is ${permissionStatus.state}`);
  //       setCameraPermission(permissionStatus.state)
  //       permissionStatus.onchange = () => {
  //         console.log(`camera permission state has changed to ${permissionStatus.state}`);
  //         setCameraPermission(permissionStatus.state)
  //       };
  //     });
  //   //eslint-disable-next-line
  // }, [])

  useEffect(() => {
    getAccountDetails(dispatch)
    sendEvent("ZONE_SCAN_INIT")
    setTimeout(() => { init() }, 1000)
    //eslint-disable-next-line
  }, [])

  const tastes = [
    "arcticblueclick",
    "summeryellowclick",
    "iceclick",
    "greenclick",
    "violetclick",
    "sunsetclick",
    "scarletclick",
    "goldtobacco",
    "darktobacco",
    "terracottatobacco",
    "meltclick",
    "foamclick"
  ]

  const modelsList = {
    neo: [
      // {
      //   url: "/assets/models_de/neo/NEO_CALOSC_BEZ_KUPONU.gltf"
      // }
      {
        url: "/assets/models_pl/BAT_AR_20_obiekty_ticket_120klatek.gltf'",
        modelOptions: {
          position: {
            x: -0.5,
            y: -12,
            z: -2
          },
          rotation: {
            y: -0.2
          },
          scale: 0.1
        }
      },
      {
        url: "/assets/models_pl/BAT_AR_21_obiekty_main_249klatek.gltf",
        modelOptions: {
          position: {
            x: 0,
            y: -4,
            z: -3
          },
          rotation: {
            y: -0.2
          },
          scale: 0.04
        }
      },
      {
        url: "/assets/models_pl/BAT_AR_20_linie_doublesided_120klatek.gltf",
        modelOptions: {
          position: {
            x: -0.1,
            y: -9.4,
            z: -5
          },
          rotation: {
            y: -0.1
          },
          scale: 0.08
        }
      },
      {
        url: "/assets/models_pl/BAT_AR_20_partikle_doublesided_120klatek.gltf",
        modelOptions: {
          position: {
            x: 0,
            y: -9,
            z: -9
          },
          rotation: {
            y: 0
          },
          scale: 0.08
        }
      },
      {
        url: "/assets/models_pl//BAT_AR_19_falka_SMOOTHED.gltf",
        modelOptions: {
          position: {
            x: 0,
            y: -6.3,
            z: -2
          },
          rotation: {
            y: -0.2
          },
          scale: 0.04
        }
      }
    ],
    neoCoupon: [
      {
        url: "/assets/models_de/veo/VEO_kupon_hover_250kl_v2.gltf",
        modelOptions: {
          position: {
            x: 0,
            y: -14.6,
            z: -2.2
          },
          rotation: {
            y: -1.5
          },
          scale: 0.1        
        }
      }
    ],
    veo: [
      {
        url: "/assets/models_de/veo/VEO_bloby_250kl.gltf",
        modelOptions: {
          position: {
            x: 0,
            y: -15,
            z: 0
          },
          rotation: {
            y: -1.5
          },
          scale: 0.1
        }
      },
      {
        url: "/assets/models_de/veo/VEO_kwiatek_kulki_250kl.gltf",
        modelOptions: {
          position: {
            x: 0,
            y: -15.3,
            z: 0
          },
          rotation: {
            y: -1.5
          },
          scale: 0.1
        }
      },
      {
        url: "/assets/models_de/veo/VEO_lawica_250kl.gltf",
        modelOptions: {
          position: {
            x: 0,
            y: -15,
            z: 0
          },
          rotation: {
            y: -1.5
          },
          scale: 0.1
        }
      },
      {
        url: "/assets/models_de/veo/VEO_platki_wew.gltf",
        modelOptions: {
          position: {
            x: 0,
            y: -15.8,
            z: 0
          },
          rotation: {
            y: -1.5
          },
          scale: 0.1
        }
      },
      {
        url: "/assets/models_de/veo/VEO_platki_zew.gltf",
        modelOptions: {
          position: {
            x: 0,
            y: -15.8,
            z: 0
          },
          rotation: {
            y: -1.5
          },
          scale: 0.1
        }
      }
    ],
    veoCoupon: [
      {
        url: "/assets/models_de/veo/VEO_kupon_hover_250kl_v2.gltf",
        modelOptions: {
          position: {
            x: 0,
            y: -15.2,
            z: -2.3
          },
          rotation: {
            y: -1.4,
            z: -0.2
          },
          scale: 0.1        
        }
      }
    ]
  }

  const loadModel = (loader, modelUrl, modelOptions) => {
    loader.load(
      modelUrl,
      function (gltf) {
        let model = gltf.scenes[0];
        model.rotation.y = modelOptions.rotation.y;
        model.position.x = modelOptions.position.x;
        model.position.y = modelOptions.position.y;
        model.position.z = modelOptions.position.z;
        model.scale.set(modelOptions.scale, modelOptions.scale, modelOptions.scale)
        model.traverse(function(obj) { obj.frustumCulled = false; });
        // if (_.filter(accountDetails?.zonePlus?.catalogue, { ident: process.env.REACT_APP_AWARD_IDENT })[0]?.available) {
          sceneRef.current.add(model);
          currentModels.push(model);
        // }
        console.log(`Model ${modelUrl} added to scene`);
        let mixer = new THREE.AnimationMixer( gltf.scene );
        mixers.current.push(mixer)
      
        gltf.animations.forEach( ( clip ) => {
          
            mixer.clipAction( clip ).play();
          
        } );
        
      },
      function (xhr) {
        // console.log((xhr.loaded / xhr.total * 100) + '% loaded' );
      },
      function (error) {
        console.error(error);
      }
    );
  }

  const init = () => {
    const { current: container } = refContainer;
    // const { current: buttonContainer } = refButton;
    if (container && !rendererRef.current) {
      let clock = new THREE.Clock();
      clockRef.current = clock

      const scene = new THREE.Scene();
      sceneRef.current = scene
    
      const camera = new THREE.PerspectiveCamera(70, window.innerWidth / window.innerHeight, 0.01, 10000);
      cameraRef.current = camera

      const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth/2, window.innerHeight/2);
      //renderer.xr.enabled = true; // we have to enable the renderer for webxr
      container.appendChild(renderer.domElement);
      rendererRef.current = renderer;
      const models = new THREE.Group();
      groupRef.current = models;
      scene.add(models);
      const pmremGenerator = new THREE.PMREMGenerator( renderer );
      scene.environment = pmremGenerator.fromScene( new RoomEnvironment(), 0.01 ).texture;
      camera.position.set( 0,0,0.1 );

      const light = new THREE.HemisphereLight(0xffffff, 0xbbbbff, 0.1);
      light.position.set(0.5, 3, 0.25);
      scene.add(light);

      // let mes;
      
      // const geometry = new THREE.IcosahedronGeometry(0.1, 1);
      // const material = new THREE.MeshPhongMaterial({
      //   color      :  new THREE.Color("rgb(226,35,213)"),
      //   shininess  :  6,
      //   flatShading:  true,
      //   transparent: 1,
      //   opacity    : 0.8
      // });
      
      // mes = new THREE.Mesh(geometry, material);
      // mes.position.set(1, 0, -0.5);

      // scene.add(mes);

      window.addEventListener('resize', onWindowResize, false);

      function onWindowResize() {
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();
    
        rendererRef.current.setSize(window.innerWidth/2, window.innerHeight/2);
      }

      return () => {
        rendererRef.current.dispose();
      };
    }
  }

  const animate = () => {
    rendererRef.current.setAnimationLoop(render);
  }

  const render = () => {
    if (controls.current) {
      controls.current.update();
    }

    let delta = clockRef.current.getDelta();

    mixers.current.forEach((mixer) => {
      if ( mixer ) mixer.update( delta );
    })

    rendererRef.current.render(sceneRef.current, cameraRef.current);
  }

  const start3D = () => {
    controls.current = new DeviceOrientationControls( cameraRef.current );
    controls.current.update();
    animate()
  }

  const getSlug = (name) => {
    let value = name
    if(value) {
      value = value.toLowerCase()
      var from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆĞÍÌÎÏİŇÑÓÖÒÔÕØŘŔŠŞŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇğíìîïıňñóöòôõøðřŕšşťúůüùûýÿžþÞĐđßÆa·/_,:;ęĘóÓąĄśŚłŁżŻźŹćĆńŃ"
      var to   = "AAAAAACCCDEEEEEEEEGIIIIINNOOOOOORRSSTUUUUUYYZaaaaaacccdeeeeeeeegiiiiinnooooooorrsstuuuuuyyzbBDdBAa______eEoOaAsSlLzZzZcCnN"

      for (var i = 0, l = from.length; i < l; i++) {
        value = value.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }

      value = value.replace(/[^a-z0-9 -]/g, '')
              .replace(/\s+/g, '_')
              .replace(/-+/g, '_')
    }
    return value
  }

  // useEffect(() => {
  //   if(coords) {
  //     let targetSet = false
  //     const lat = coords.latitude.toFixed(3)
  //     const lng = coords.longitude.toFixed(3)
  //     for (let i = 0; i < places.length; i++) {
  //       const element = places[i];
  //       const placeLat = element.lat.toFixed(3)
  //       const placeLng = element.lng.toFixed(3)
  //       if(parseFloat(lat) < parseFloat(placeLat)+distance && parseFloat(lat) > parseFloat(placeLat)-distance && parseFloat(lng) < parseFloat(placeLng)+distance && parseFloat(lng) > parseFloat(placeLng)-distance) {
  //         targetSet = true
  //         targetName = getSlug(element.name + " " + element.address)
  //       }
  //     }
  //     if(targetSet === true) {
  //       inTarget = true
  //     } else {
  //       inTarget = false
  //       targetName = ""
  //     }
  //   }
  // }, [coords])

  const redeemTutorialBonus = (product) => {
    const data = {
      activation: {
        ident: product === "veo" ? "ar_tutorial_veo" : product === "neo" ? "ar_tutorial_neo" : "",
        payload: null
      }
    }
    
    fetch(process.env.REACT_APP_API_URL + "/activation/solution/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem('token')).uuid
      },
      body: JSON.stringify(data)
    })
      .then((response) => {
        return response.json()
      })
      .then(
        (result) => {
          if (result.status?.success) {
            setPayout(result.data.payout)
            getAccountDetails(dispatch)
            setLoadingA(false)
            // setCorrectAnswersMap(result.data.answers)
          } else {
            showErrorPopup(translateError(result.data.error), result.meta.ts)
            setHideUI(false)
            setLoadingA(false)
          }
        },
        (error) => {
          showErrorPopup(translateError("generic"))
          setLoadingA(false)
          console.log(error)
        }
      )
  }

  const redeemAward = () => {
    const data = {
      catalogue: {
        ident: process.env.REACT_APP_AWARD_IDENT
      },
      payload: {
        type: "test"
      }
    }
    
    fetch(process.env.REACT_APP_API_URL + "/ar/prize/redeem", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem('token')).uuid
      },
      body: JSON.stringify(data) 
    })
      .then((response) => {
        return response.json()
      })
      .then(
        (result) => {
          if (result.status?.success) {
            setCode(result.data?.award?.code)
            // setCorrectAnswersMap(result.data.answers)
          } else {
            showErrorPopup(translateError(result.data.error), result.meta.ts)
          }
        },
        (error) => {
          showErrorPopup(translateError("generic"))
          console.log(error)
        }
      )
  }

  const getAward = (product) => {
    setLoadingA(true)
    if(accountDetails?.zonePlus?.showTutorial) {
      if(product === "neo" && accountDetails?.zonePlus?.tutorialCompleted?.neo === true) {
        setHideUI(false)
        setLoadingA(false)
      } else if((product === "veo" || product === "glo") && accountDetails?.zonePlus?.tutorialCompleted?.veo === true) {
        setHideUI(false)
        setLoadingA(false)
      } else {
        redeemTutorialBonus(product)
      }
    } else {
      // redeemAward()
      setHideUI(false)
      setLoadingA(false)
    }
  }

  const sendImgToApi = async (image, ua, lat, long, inTarget, status) => {
    const mimeString = image.split(':')[1].split(';')[0]
    const blob = new Blob([ua], {type: mimeString})
    
    let payloadForm = new FormData()

    const input = {
      "scan": {
        "status": status,
        "latitude": lat || "",
        "longitude": long || "",
        "inTarget": inTarget !== "" ? inTarget : "wrong_location",
        "tutorial": accountDetails?.zonePlus?.showTutorial ? true : false
      }
    }

    payloadForm.append('files[]', blob)
    payloadForm.append('_input', JSON.stringify(input))
    
    const response = await fetch(process.env.REACT_APP_API_URL + "/ar/scan", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + JSON.parse(sessionStorage.getItem('token')).uuid
      },
      body: payloadForm,
    })

    const res = await response.json()
    if(res) console.log("saved")
  }

  const checkTaste = (array) => {
    let detected = false
    let taste = ""
    let confidence = 0
    for (let i = 0; i < array.length; i++) {
      const element = array[i];
      if(tastes.includes(element.DetectedText.toLocaleLowerCase().split(" ").join(""))) {
        taste = element.DetectedText.toLocaleLowerCase().split(" ").join("")
        confidence = element.Confidence
        detected = true
        break
      }
    }
    return detected ? {taste: taste, confidence: confidence} : false
  }

  const sendImage = (image, lat, long) => {
    sendEvent("ZONE_SCAN_START")
    setToastDesc("Scannen läuft...")    
    const client = new RekognitionClient({
      region: "eu-central-1",
      credentials: fromCognitoIdentityPool({
        client: new CognitoIdentityClient({ region: "eu-central-1" }),
        identityPoolId: 'eu-central-1:23db6dd2-96c6-4725-af6d-ef9ada1300f5',
      })
    })

    let img = atob(image.split("data:image/jpeg;base64,")[1])

    const length = img.length;
    const imageBytes = new ArrayBuffer(length);
    const ua = new Uint8Array(imageBytes);
    for (var i = 0; i < length; i++) {
      ua[i] = img.charCodeAt(i);
    }

    const params = {
      Image: {
        Bytes: ua
      },
      MaxLabels: 10,
      MinConfidence: 20,
      ProjectVersionArn: "arn:aws:rekognition:eu-central-1:773704893069:project/GLO/version/GLO.2022-06-15T13.19.02/1655291942240"
    };
    
    const command = new DetectTextCommand(params);

    client.send(command).then(
      (data) => {
        // console.log(data)
        // setData(data.TextDetections)
        setDebug(data.TextDetections)
        setToastDesc("")
        let detected = false
        let taste = ""
        let prod = ""

        for (let i = 0; i < data.TextDetections.length; i++) {
          const element = data.TextDetections[i];
          if(element.DetectedText === "neo") {
            prod = "neo"
            detected = true
            if(checkTaste(data.TextDetections)) {
              let t = checkTaste(data.TextDetections)
              taste = t.taste
              break
            } else {
              break
            }
          } else if(element.DetectedText === "veo" || element.DetectedText === "ueo") {
            prod = "veo"
            detected = true
            if(checkTaste(data.TextDetections)) {
              let t = checkTaste(data.TextDetections)
              taste = t.taste
              break
            } else {
              break
            }
          } else if(element.DetectedText === "glo" || element.DetectedText === "gl") {
            prod = "glo"
            detected = true
            if(checkTaste(data.TextDetections)) {
              let t = checkTaste(data.TextDetections)
              taste = t.taste
              break
            } else {
              break
            }
          }
        }

        setProduct(prod)

        if(detected) {
          sendImgToApi(image, ua, lat, long, targetName, prod ? prod : "fail")
          /*console.log(groupRef?.current)
          groupRef?.current?.traverse(function(child){
            groupRef?.current?.remove(child);
          });*/
          console.log(currentModels)
          if (currentModels.length > 0) {
            currentModels.forEach((child) => sceneRef.current.remove(child))
            currentModels = []
          }
          
          if(prod === "glo") {
            sendEvent("ZONE_SCAN_SUCCESS_GLO")

            const loader = new GLTFLoader();

            modelsList.veo.forEach((model) => {
              loadModel(loader, model.url, model.modelOptions)
            })

            if(accountDetails?.zonePlus?.tutorialCompleted?.veo === false) {
              modelsList.veoCoupon.forEach((model) => {
                loadModel(loader, model.url, model.modelOptions)
              })
            }
          } else if(prod === "veo") {
            sendEvent("ZONE_SCAN_SUCCESS_VEO")

            const loader = new GLTFLoader();

            modelsList.veo.forEach((model) => {
              loadModel(loader, model.url, model.modelOptions)
            })

            if(accountDetails?.zonePlus?.tutorialCompleted?.veo === false) {
              modelsList.veoCoupon.forEach((model) => {
                loadModel(loader, model.url, model.modelOptions)
              })
            }
          } else {
            sendEvent("ZONE_SCAN_SUCCESS_NEO")

            const loader = new GLTFLoader();

            if(accountDetails?.zonePlus?.tutorialCompleted?.neo === false) {
              modelsList.neo.forEach((model) => {
                loadModel(loader, model.url, model.modelOptions)
              })
              
              modelsList.neoCoupon.forEach((model) => {
                loadModel(loader, model.url, model.modelOptions)
              })
            } else {
              modelsList.neo.forEach((model) => {
                loadModel(loader, model.url, model.modelOptions)
              })

              /*modelsList.neoCoupon.forEach((model) => {
                loadModel(loader, model.url, model.modelOptions)
              })*/
            }
          }
          // navigate(`/ar/${typeParam}/${locationParam}`, { replace: true })
          setHideUI(true)
        } else {
          setTimeout(() => { setToastDesc("<strong>Ein Fehler ist aufgetreten, </strong></br>bitte versuche es erneut!") }, 400)
          setTimeout(() => { setToastDesc("") }, 3400)
          sendImgToApi(image, ua, lat, long, targetName, prod ? prod : "fail")
        }
        
        if(targetName !== "") sendEvent("ZONE_LOCATION_"+targetName)
        setImage("")
        setLoading(false)
      },
      (error) => {
        console.log(error)
        setToastDesc("")
        setTimeout(() => { setToastDesc("<strong>Ein Fehler ist aufgetreten, </strong></br>bitte versuche es erneut!") }, 400)
        setTimeout(() => { setToastDesc("") }, 3400)
        setImage("")
        setLoading(false)
      }
    );
  }

  const capture = useCallback(
    (lat, long) => {
      setLoading(true)
      const imageSrc = webcamRef.current.getScreenshot();
      setImage(imageSrc)
      console.log(lat, long)
      sendImage(imageSrc, lat, long)
    },
    //eslint-disable-next-line
    [webcamRef]
  );

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "environment"
  };

  return (
    <div className="capture-image">
      <div className='help-button' onClick={() => {setHelp(!help) }} onMouseOut={() => { setHelp(false) }}>
        <div className='help-button-icon'><img src={HelpIcon} alt="Help"/></div>
        <div className={ help ? 'help-content show' : 'help-content' }>
          <div><img src={HelpIcon} alt="Help"/></div>
          <div>
            <h3>Wie wird gescannt?</h3>
            <p>Bereite die Verpackung vor und positioniere sie auf dem Bildschirm so, dass sie vollständig sichtbar ist. Wenn die Verpackung korrekt gescannt wird, erhältst du Punkte!</p>
          </div>
        </div>
      </div>
      {/* { !firstRun ? <div className={ inTarget ? 'location in-target' : 'location' }>
        <Target className="target"/>
        <TargetChecked className="target-checked"/>
      </div> : null } */}
      {/* { coordStats && <div className='coords-stats'>
        <p><strong>lat: </strong>{ parseFloat(coords?.latitude) }</p>
        <p><strong>lng: </strong>{ parseFloat(coords?.longitude) }</p>
        { places.map((item, i) => {
          let valid = false

          if(parseFloat(coords?.latitude) < parseFloat(item.lat)+distance && parseFloat(coords?.latitude) > parseFloat(item.lat)-distance && parseFloat(coords?.longitude) < parseFloat(item.lng)+distance && parseFloat(coords?.longitude) > parseFloat(item.lng)-distance) {
            valid = true
          }

          return (
            <div key={i+coords?.latitude} className={valid ? "valid" : ""}>
              <p><strong>{item.name}:</strong></p>
              <p>{"placeLat: " + parseFloat(item.lat).toFixed(7)}</p>
              <p>{"placeLat+: " + (parseFloat(item.lat)+distance).toFixed(7)}</p>
              <p>{"placeLat-: " + (parseFloat(item.lat)-distance).toFixed(7)}</p>
              <p>{"placeLng: " + parseFloat(item.lng).toFixed(7)}</p>
              <p>{"placeLng+: " + (parseFloat(item.lng)+distance).toFixed(7)}</p>
              <p>{"placeLng-: " + (parseFloat(item.lng)-distance).toFixed(7)}</p>
            </div>
          )
        }) }
      </div> } */}
      <Webcam key={cameraPermission} className='camera' audio={false}
        height={720}
        screenshotFormat="image/jpeg"
        ref={webcamRef}
        videoConstraints={videoConstraints}/>
      { !hideUI && <div className='scan'>
        <div className='left'></div>
        <div className='right'></div>
      </div> }
      <div
        className={ hideUI ? "canvas show" : "canvas" }
        style={{ height: window.innerHeight, width: window.innerWidth, position: "relative" }}
        ref={refContainer}
      >
      </div>
      <div ref={refButton} style={{ position: 'absolute', bottom: 40, width: '100%'}}></div>
      { !hideUI && <button className={loading ? "spin" : ""} disabled={toastDesc ? true : false} onClick={() => { capture(coords?.latitude, coords?.longitude); start3D() }}><img src={Scan} alt="Capture"/></button> }
      { hideUI && <button className='next' onClick={ () => {
          if(!loadingA) getAward(product)
      } }><img src={ Arrow } alt=""/></button> }
      {image && <div className='capture-holder'>
        <div className='close' onClick={() => { setImage("") }}></div>
        <img className='captured' src={image} alt=""/>
        {/* <ul>
          {data?.map((item, i) => {
            return (
              <li key={i} className="bounding" style={{width: (item.Geometry.BoundingBox.Width*100)+"%", height: (item.Geometry.BoundingBox.Height*100)+"%", left: (item.Geometry.BoundingBox.Left*100)+"%", top: (item.Geometry.BoundingBox.Top*100)+"%"}}><span>{item.Confidence.toFixed(2)}</span></li>
            )
          })}
        </ul> */}
        <div className="diode">
          <div className="laser"></div>
        </div>
      </div> }
      <Toast desc={ toastDesc }/>
      {/* { data && <div><code>{JSON.stringify(data)}</code></div> } */}
      {/* { cameraPermission === "granted" ? <> */}
        { code ? <Intro code={code} onClose={() => { getAccountDetails(dispatch); navigate("/scanner", { replace: true }); setCode("") }}/> : null }
        { payout ? <Tutorial payout={payout} onClose={() => { getAccountDetails(dispatch); setPayout(0); setHideUI(false) }}/> : null }
      {/* </> : <>
        <CameraPrompt/>
      </> } */}
    </div>
  );
}

export default App;
