import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation
} from "react-router-dom";
import Modal from 'react-modal';
import { ProtectedRoute } from "./components/ProtectedComponent";
import { getUrlParam } from "./UrlParamsHelper";
import { loginByToken, useUserDispatch, useUserState } from "./UserContext";
import Exit from "./components/ErrorHandling/img/exit.svg"
import ErrorHandling from "./components/ErrorHandling"
import LogoutWarning from "./components/LogoutWarning";
import Maintenance from "./components/Maintenance";
import NotFound from "./components/NotFound";
import Switch from "./components/Switch"
import Welcome from "./pages/Welcome";
import Map from "./pages/Map";
import CaptureImage from "./pages/CaptureImage"
import Button from "./components/Button/Button";
import ARComponent from "./pages/ARComponent";

function Transition() {
  let location = useLocation();
  const dispatch = useUserDispatch()
  const { userDetails } = useUserState()
  const [loader, setLoader] = useState(true)
  const [backPopup, setBackPopup] = useState(false)
  const [debug, setDebug] = useState([])

  useEffect(() => {
    if(getUrlParam("token") && !Boolean(userDetails.token?.uuid)) {
      loginByToken(dispatch, getUrlParam("token"))
    } else if(!userDetails.firstName) {
      document.location.href = process.env.REACT_APP_GLOPLUS_URL+"/zone-plus"+(process.env.REACT_APP_ENV === "dev" ? `?arReturnUrl=${window.origin}` : "")
    }
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if(userDetails?.firstName) {
      setLoader(false)
    }
  }, [userDetails])

  return (
      <>
        { loader ? <div className="loading">
          <h2>Loading...</h2>
        </div> : <div className="app">
          <LogoutWarning/>
          <div className="page-holder">
            <button className={ "back-button az" } onClick={ () => { setBackPopup(true) } }></button>
            <Switch/>
            <div>
              <Routes location={location}>
                <Route path={`${process.env.PUBLIC_URL}/`} exact element={<ProtectedRoute><Welcome/></ProtectedRoute>}/>
                <Route path={`${process.env.PUBLIC_URL}/scanner`} exact element={<ProtectedRoute><CaptureImage setDebug={setDebug}/></ProtectedRoute>}/>
                <Route path={`${process.env.PUBLIC_URL}/scanner/coupon`} exact element={<ProtectedRoute><CaptureImage setDebug={setDebug}/></ProtectedRoute>}/>
                {/* <Route path={`${process.env.PUBLIC_URL}/mapa`} exact element={<ProtectedRoute><Map/></ProtectedRoute>}/> */}
                <Route path={`${process.env.PUBLIC_URL}/ar`}>
                  <Route index element={<ProtectedRoute><ARComponent/></ProtectedRoute>} />
                  <Route path=":type" element={<ProtectedRoute><ARComponent/></ProtectedRoute>} />
                  <Route path=":type/:location" element={<ProtectedRoute><ARComponent/></ProtectedRoute>} />
                </Route>
                <Route path={`${process.env.PUBLIC_URL}/503`} exact element={<Maintenance/>}/>
                <Route path={`${process.env.PUBLIC_URL}/404`} exact element={<NotFound/>}/>
                <Route path={`${process.env.PUBLIC_URL}/*`} exact element={<NotFound/>}/>
              </Routes>
            </div>
            <ErrorHandling/>
          </div>
          <Modal isOpen={backPopup} className={"error-modal"} overlayClassName={"error-overlay"}>
            <>
              <div className="button-wrapper">
                <button className='close-button'>
                  <img src={Exit} alt="" onClick={ () => { setBackPopup(false) } } />
                </button>
              </div>
              <div className="content">
                <h2>Bist du sicher, dass du Zone+ verlassen</h2>
                <div className='error-description'>und zurück zu glo+ möchtest?</div>
                <div className="buttons">
                  <Button action={ () => { setBackPopup(false) } } btnClass="white">Nein</Button>
                  <Button action={ () => { document.location.href = process.env.REACT_APP_GLOPLUS_URL + "/zone-plus?ageGate=false&token=" + JSON.parse(sessionStorage.getItem('token')).uuid } } btnClass="orange">Ja</Button>
                </div>
              </div>
            </>
          </Modal>
          { debug?.length > 0 && getUrlParam("debug") === "true" && <div className='coords-stats'>
            <div className='close-debug' onClick={() => { setDebug([]) }}></div>
            <p><strong>Detected:</strong></p>
            <ul>
            { debug.map((item, i) => {
              return (
                <li key={i} className={ item.DetectedText === "glo" || item.DetectedText === "neo" || item.DetectedText === "gl" ? "valid" : "" }>
                  <p className="flex"><span>{item.DetectedText}:</span> <span>{item.Confidence.toFixed(2)}</span></p>
                </li>
              )
            }) }
            </ul>
          </div> }
        </div> }
      </>
  );
}

export default function MainView() {
  return (
    <Router>
      <Transition />
    </Router>
  );
}
