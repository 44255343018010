let user = sessionStorage.getItem("currentUser") ? JSON.parse(sessionStorage.getItem("currentUser")) : {};
let account = sessionStorage.getItem("accountDetails") ? JSON.parse(sessionStorage.getItem("accountDetails")) : {};
let token = sessionStorage.getItem("token") ? JSON.parse(sessionStorage.getItem("token")) : {};
 
export const initialState = {
  userDetails: user || {},
  accountDetails: account || {},
  token: token || {},
  loading: false,
  errorMessage: null
};
 
export const UserReducer = (initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        ...initialState,
        loading: true,
        errorMessage: null
      };
    case "LOGIN_SUCCESS":
      return {
        ...initialState,
        token: action.payload.token,
        loading: false,
        errorMessage: null
      };
 
    case "LOGIN_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error
      };

    case "REFRESH":
      return {
        ...initialState,
        loading: true,
        errorMessage: null
      };
    case "REFRESH_SUCCESS":
      return {
        ...initialState,
        token: action.payload.token,
        loading: false,
        errorMessage: null
      };
 
    case "REFRESH_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error
      };

    case "USER_DETAILS":
      return {
        ...initialState,
        loading: true,
        errorMessage: null
      };
    case "USER_DETAILS_SUCCESS":
      return {
        ...initialState,
        userDetails: action.payload,
        loading: false,
        errorMessage: null
      };
  
    case "USER_DETAILS_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error
      };

    case "ACCOUNT_DETAILS":
      return {
        ...initialState,
        loading: true,
        errorMessage: null
      };
    case "ACCOUNT_DETAILS_SUCCESS":
      return {
        ...initialState,
        accountDetails: action.payload,
        loading: false,
        errorMessage: null
      };
  
    case "ACCOUNT_DETAILS_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error
      };

    case "LOGOUT":
      return {
        ...initialState,
        loading: true,
        errorMessage: null
      };

    case "LOGOUT_SUCCESS":
      return {
        ...initialState,
        loading: false,
        userDetails: {},
        token: {},
        accountDetails: {},
        errorMessage: null
      };

    case "LOGOUT_ERROR":
      return {
        ...initialState,
        loading: false,
        userDetails: {},
        token: {},
        accountDetails: {},
        errorMessage: action.error
      };
 
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};