import React, { useEffect, useState } from "react"
import { useLocation, NavLink } from "react-router-dom"
import "./style.scss"

export default function NotFound () {
  const { pathname } = useLocation()
  const [show, setShow] = useState(false)

  useEffect(() => {
    if(pathname === "/skaner" || pathname === "/mapa" || pathname === "/skaner/" || pathname === "/mapa/") {
      setShow(true)
    } else {
      setShow(false)
    }
  }, [pathname])

  return (
    <ul className={ (show ? "switch show " : "switch ") + pathname.replace("/", "") }>
      <li>
        <NavLink to="/skaner">skaner</NavLink>
      </li>
      <li>
        <NavLink to="/mapa">mapa</NavLink>
      </li>
    </ul>
  )
}
