export const errorDefinitions = [
  // error_newsletter_empty_queue
  {
    ident: "error_level_too_low",
    title: "DA IST WAS SCHIEF GELAUFEN",
    desc: "Höherer Status erforderlich",
    button: "Sammle mehr Punkte",
    returnUrl: "/aufgaben",
    disableClose: false,
    updateUserData: false,
  },
  {
    ident: "error_not_enough_credits",
    title: "DA IST WAS SCHIEF GELAUFEN",
    desc: "Hast du genug Punkten?",
    button: "Sammle mehr",
    returnUrl: "/aufgaben",
    disableClose: false,
    updateUserData: false,
  },
  {
    ident: "error_empty_award_databse",
    title: "DA IST WAS SCHIEF GELAUFEN",
    desc: "Die Codes für den Preis sind ausverkauft",
    button: "OK",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "redeem_error_critical_exception",
    title: "DA IST WAS SCHIEF GELAUFEN",
    desc: "Beim Erhalt der Auszeichnung ist ein Fehler aufgetreten",
    button: "Zum Eingelöst",
    returnUrl: "/pramienshop/eingelost",
    disableClose: false,
    updateUserData: false
  },
  // {
    //   ident: "error_voucher_please_wait",
    //   title: "Nie tak szybko - SMS jest już w drodze",
    //   desc: "SMS z linkiem do odbioru kuponu został wysłany przy poprzedniej próbie i jest już w drodze na podany poprzednio numer. Jeżeli nie otrzymasz go w ciągu 5 minut sprawdź wpisywany numer telefonu i spróbuj ponownie.",
    //   button: "Zamknij",
    //   returnUrl: "/login",
  //   disableClose: false,
  //   updateUserData: false
  // },
  {
    ident: "error_wrong_user_status",
    title: "Twoje konto jest już aktywne - czas się zalogować.",
    desc: "Jeżeli nie pamiętasz swojego hasła, skorzystaj z opcji \"nie pamiętam hasła\" by ustawić nowe.",
    button: "Login again",
    disableClose: false,
    updateUserData: false,
    logout: true
  },
  {
    ident: "error_token_invalid",
    title: "Twój link stracił już ważność.",
    desc: "Skorzystaj z opcji \"nie pamiętam hasła\" by uzyskać nowy link ustawienia hasła.",
    button: "Login again",
    disableClose: false,
    updateUserData: false,
    logout: true
  },
  {
    ident: "error_unauthorized",
    title: "DU BIST LEIDER NICHT MEHR EINGELOGGT.",
    desc: "Logge dich ein nochmal, um weiterzumachen.",
    button: "Zum Login",
    returnUrl: null,
    disableClose: true,
    updateUserData: false,
    logout: true
  },
  {
    ident: "error_account_temporary_blocked",
    title: "Twoje konto zostało tymczasowo zablokowane.",
    desc: "<p>Wykryliśmy nietypową aktywność na Twoim koncie. Aktualnie weryfikujemy tę sytuację i wkrótce skontaktujemy się z Tobą.</p><p>Jeśli potrzebujesz pomocy, napisz na adres: <a href=\"mailto:kontakt@alterzone.pl\">kontakt@alterzone.pl</a><br/>Przepraszamy za niedogodności.</p>",
    button: "Close",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  // {
  //   ident: "error_m2_already_registered",
  //   title:"Konto o podanym adresie już istnieje.",
  //   desc:"Jeśli posiadasz konto w sklepie internetowym discoverglo.com lub na platformie Masz Wybór użyj tych samych danych logowania. <br/>Spróbuj się zalogować lub kliknij „Nie pamiętam hasła”, aby je zresetować.",
  //   button: "Zmień hasło",
  //   returnUrl: "/login/reset",
  //   disableClose: false,
  //   updateUserData: false
  // },
  {
    ident: "error_m2_wrong_credentials_or_disabled",
    title:"DA IST WAS SCHIEF GELAUFEN",
    desc:"Upewnij się, że poprawnie wpisujesz dane. Spróbuj zalogować się raz jeszcze.",
    button: "Login again",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_m2_empty_field",
    title: "DA IST WAS SCHIEF GELAUFEN",
    desc: "Bitte versuch’s nochmal",
    button: "OK",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_token_has_been_already_used",
    title: "DU BIST LEIDER NICHT MEHR EINGELOGGT.",
    desc: "Logge dich ein nochmal, um weiterzumachen.",
    button: "Zum Login",
    returnUrl: null,
    disableClose: true,
    updateUserData: false
  },
  // {
  //   ident: "error_customer_not_found_by_email",
  //   title: "Wystąpił błąd.",
  //   desc: "Zacznij od początku.",
  //   button: "Close",
  //   returnUrl: null,
  //   disableClose: false,
  //   updateUserData: false
  // },
  {
    ident: "error_customer_not_found_by_token",
    title: "DA IST WAS SCHIEF GELAUFEN",
    desc: "Bitte versuch’s nochmal",
    button: "Zum Login",
    returnUrl: null,
    disableClose: true,
    updateUserData: false,
    logout: true
  },
  {
    ident: "error_already_answered",
    title: "DA IST WAS SCHIEF GELAUFEN",
    desc: "Odpowiedź na pytanie konkursowe została już przesłana.",
    button: "Close",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_token_expired",
    title: "DA IST WAS SCHIEF GELAUFEN",
    desc: "Logge dich ein nochmal, um weiterzumachen.",
    button: "Zum Login",
    returnUrl: null,
    disableClose: true,
    updateUserData: false,
    logout: true
  },
  // {
  //   ident: "error_voucher_already_assigned",
  //   title: "Wystąpił błąd.",
  //   desc: "Podany numer telefonu istnieje już w bazie, spróbuj ponownie podając nowy numer telefonu",
  //   button: "Close",
  //   returnUrl: null,
  //   disableClose: false,
  //   updateUserData: false
  // },
  {
    ident: "error_verification_required",
    title: "Ograniczyliśmy dostęp do Twojego konta.",
    desc: '<p>Wykryliśmy nietypową aktywność na koncie, do którego próbujesz się zalogować.* Ze względów bezpieczeństwa i w celu zweryfikowania informacji tymczasowo zablokowaliśmy do niego dostęp.</p><p>Aby odblokować konto, postępuj zgodnie z instrukcją, którą wysłaliśmy na Twój adres e-mail.</p><p class="disclaimer">*Blokada konta pojawia się, jeśli wykryjemy naruszenie zasad korzystania z platformy Masz Wybór. <a href="/assets/regulamin_serwisu_maszwybor.pdf" target="_blank">Dowiedz się więcej</a></p>',
    button: "Close",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_m2_token_error",
    title: "DA IST WAS SCHIEF GELAUFEN",
    desc: "Logge dich ein nochmal, um weiterzumachen.",
    button: "Zum Login",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  // {
  //   ident: "first_voucher",
  //   title: "Cześć!",
  //   desc: "<p><strong>Przy wymianie ponad 200 punktów w danym miesiącu na nagrody, potrzebne będzie uzupełnienie dodatkowych danych koniecznych do wystawienia Ci PIT-11.</strong></p><p>Przy próbie pobrania nagrody skontaktujemy się z Tobą mailowo w celu zebrania niezbędnych danych, a następnie przekażemy Ci Twoją nagrodę.</p>",
  //   button: "Close",
  //   returnUrl: null,
  //   disableClose: false,
  //   updateUserData: false
  // },
  // {
  //   ident: "error_monthly_limit_exceeded", // Po kliknięciu "Dalej" docelowo pokazujemy tax form, przydałby sie projekt. Do uzupełnienia opis poniżej.
  //   title: "Wystąpił błąd.",
  //   desc: "<p><strong>Aktualnie nie można odebrać nagrody, przepraszamy za problemy techniczne.</strong></p>",
  //   button: "Dalej",
  //   returnUrl: null,
  //   disableClose: false,
  //   updateUserData: false,
  //   customAction: "showTaxForm" // Ident of custom action.
  // },
  {
    ident: "error_prize_exception",
    title: "DA IST WAS SCHIEF GELAUFEN",
    desc: "Aktualnie nie można odebrać nagrody, przepraszamy za problemy techniczne.",
    button: "Close",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "generic",
    title: "DA IST WAS SCHIEF GELAUFEN",
    desc: "Bitte versuch’s nochmal oder melde dich nochmal an.",
    button: "OK",
    returnUrl: null,
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_contest_closed",
    title: "DA IST WAS SCHIEF GELAUFEN",
    desc: 'Konkurs stracił ważność.',
    button: "OK",
    returnUrl: null,
    disableClose: true,
    updateUserData: false
  },
  {
    ident: "error_slot_chosen",
    title: "DA IST WAS SCHIEF GELAUFEN",
    desc: 'Miejsce na lot zostało już zarezerwowane.',
    button: "Close",
    returnUrl: "null",
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_slot_taken",
    title: "DA IST WAS SCHIEF GELAUFEN",
    desc: 'Wybrana data i godzina jest już niedostępna',
    button: "Close",
    returnUrl: "null",
    disableClose: false,
    updateUserData: false
  },
  {
    ident: "error_daily_limit_exceeded",
    type: "small_popup_random",
    title: "Du kannst täglich nur eine solche Prämie sammeln",
    desc: "Wir sehen uns morgen früh",
    button: "Zum Prämienshop",
    returnUrl: "/pramienshop",
    disableClose: false,
    updateUserData: false
  }
]

export const customActions = {
  showTaxForm: () => { console.log("showTaxForm") }
}