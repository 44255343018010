import './style.scss';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import Arrow from "./img/arrow.svg"
import { getAccountDetails, useUserDispatch, useUserState } from '../../UserContext';
// import _ from 'lodash'
import IntroImage1 from "./img/intro_1.jpg"
import IntroImage2 from "./img/intro_2.jpg"
import IntroImage3 from "./img/intro_3.jpg"
import Button from '../../components/Button/Button';

const steps = [
    {
        title: "Willkommen beim Zone+ Scanner",
        description: "In wenigen Schritten führen wir dich in die Welt der glo™ Augmented Reality und der Extrapunkte ein!",
        image: IntroImage1
    },
    {
        title: "Scanne ZUERST eine neo™ oder veo™ Packung.",
        description: "Deine erste Aufgabe wird sein, eine neo™ oder veo™ Packung zu scannen und deine ersten Punkte zu verdienen.<br/><br/>Achte darauf, dass das Logo von neo™ auf der Vorderseite der Verpackung zu sehen ist.",
        image: IntroImage2
    },
    {
        title: "Einzigartig: der erste Scan!",
        description: "Hast du eine der neo™ oder veo™ Packungen in der Hand? Scanne sie. Aber das ist noch nicht alles! Wir werden deinem glo+ Konto 20 Punkte für das Scannen von neo und 25 Punkte für das Scannen von veo Packungen hinzufügen!",
        image: IntroImage3
    }
]

const Tutorial = ({step, setStep, cameraPrompt}) => {
    const [animate, setAnimate] = useState("")

    useEffect(() => {
        setTimeout(() => { setAnimate("animate") }, 800)
    }, [])

    return (
        <div className={ 'bottom-modal tutorial ' + animate }>
            <ul className='dots'>
                <li className={step === 0 ? "active" : ""}></li>
                <li className={step === 1 ? "active" : ""}></li>
                <li className={step === 2 ? "active" : ""}></li>
            </ul>
            <div className='content'>
                <h3 dangerouslySetInnerHTML={{ __html: steps[step].title }}></h3>
                <p dangerouslySetInnerHTML={{ __html: steps[step].description }}></p>
                <img className='icon' src={ steps[step].icon } alt=""/>
            </div>
            <button className='next' onClick={ () => {
                if(step < 2) {
                    setStep(step+1)
                } else {
                    setAnimate("")
                    cameraPrompt()
                }
            } }><img src={ Arrow } alt=""/></button>
        </div>
    )
}

// const Intro = () => {
//     const { accountDetails } = useUserState()
//     const navigate = useNavigate()
//     const step = _.filter(accountDetails?.zonePlus?.catalogue, { ident: process.env.REACT_APP_AWARD_IDENT })[0]?.available ? 1 : 0
//     const [animate, setAnimate] = useState("")

//     const steps = [
//         {
//             title: "Co tydzień nowa nagroda!",
//             description: "Zgarniaj kupony skanując paczkę neo™ lub logo glo™ <br/>w jednym z&nbsp;punktów biorących udział w akcji.<br/><span>Pamiętaj, żeby na paczce<br/>widoczne było logo neo™</span>"
//         },
//         {
//             title: "Na mieście jest nagroda!",
//             description: "Wpadnij do wybranego miejsca<br/>biorącego udział w akcji i odbierz kupon<br/>skanując paczkę neo™ lub logo glo™.<br/><span>Pamiętaj, żeby na paczce<br/>widoczne było logo neo™</span>"
//         }
//     ]

//     useEffect(() => {
//         setTimeout(() => { setAnimate("animate") }, 800)
//     }, [])

//     return (
//         <div className={ 'bottom-modal intro ' + animate }>
//             <div className='content'>
//                 <h3 dangerouslySetInnerHTML={{ __html: steps[step].title }}></h3>
//                 <p dangerouslySetInnerHTML={{ __html: steps[step].description }}></p>
//             </div>
//             <button className='next' onClick={ () => {
//                 setAnimate("")
//                 setTimeout(() => { navigate("/scanner") }, 500)
//             } }><img src={ Arrow } alt=""/></button>
//         </div>
//     )
// }

function Welcome() {
  const navigate = useNavigate()
  const dispatch = useUserDispatch()
  const { accountDetails } = useUserState()
  const [step, setStep] = useState(0)
  const [cameraPermission, setCameraPermission] = useState(true)
//   const navigate = useNavigate()

  useEffect(() => {
    getAccountDetails(dispatch)
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    // if(accountDetails && !accountDetails?.zonePlus?.showTutorial) navigate("/scanner")
    //eslint-disable-next-line
  }, [accountDetails])

  const cameraPrompt = () => {
    // navigator.getUserMedia (
    //     {
    //        video: true,
    //        audio: false
    //     },

    //     function() {
    //         setTimeout(() => { navigate("/scanner") }, 500)
    //         console.log("camera permission allowed")
    //     },
     
    //     function(err) {
    //         setCameraPermission(false)
    //         console.log("camera permission denied")
    //     }
    //  );
     navigator.mediaDevices
        .getUserMedia({
            audio: false,
            video: true,
          })
        .then((stream) => {
            setTimeout(() => { window.location.href="/scanner" }, 500)
        })
        .catch((err) => {
            setCameraPermission(false)
        });
  }

  return (
    <>
        { cameraPermission ? <div className="welcome-page">
            <div className={ step === 0 ? "bg-img active" : "bg-img" } style={{ backgroundImage: "url(" + steps[0].image + ")" }}></div>
            <div className={ step === 1 ? "bg-img active" : "bg-img" } style={{ backgroundImage: "url(" + steps[1].image + ")" }}></div>
            <div className={ step === 2 ? "bg-img active" : "bg-img" } style={{ backgroundImage: "url(" + steps[2].image + ")" }}></div>
            <Tutorial step={step} setStep={setStep} cameraPrompt={cameraPrompt}/>
        </div> : <div className='welcome-page camera-permission'>
            <h3>Zu dunkel hier!</h3>
            <p>Du musst die Kameranutzung erlauben, damit Zone+ richtig funktioniert.</p>
            <Button action={() => { setCameraPermission(true); navigate("/scanner") }}>Geschafft!</Button>
        </div> }
    </>
  );
}

export default Welcome;
