import './style.scss';
import { useEffect, useState } from 'react';
import L from "mapbox.js"
import "mapbox.js/dist/mapbox.css"
import "leaflet.markercluster"
import "leaflet.markercluster/dist/MarkerCluster.css"
import "leaflet.markercluster/dist/MarkerCluster.Default.css"
import List from "./img/list.svg"
import Marker from "./img/marker.svg"
import Location from "./img/location.svg"
import { places } from '../../data/places';
import SearchInput, {createFilter} from 'react-search-input'

const LocationsList = ({setMapPosition, onClose}) => {
    const [animate, setAnimate] = useState("")
    const [search, setSearch] = useState("")

    const KEYS_TO_FILTERS = ['name', 'address']

    useEffect(() => {
        setAnimate("animate")

        return () => {
            setAnimate("")
        }
    }, [])

    const filteredPlaces = places.filter(createFilter(search, KEYS_TO_FILTERS))

    return (
        <div className={ 'bottom-modal tutorial ' + animate }>
            <div className='search-input'>
              <input value={search} onChange={(e) => { setSearch(e.target.value) }} placeholder="Wyszukaj" type="text"/>
              { search !== "" && <span className='clear' onClick={() => { setSearch("") }}></span> }
            </div>
            <div className='content'>
                <ul className='location-list'>
                    { filteredPlaces.filter(item => !item.hidden).map((item, i) => {
                        return (
                            <li key={i} onClick={() => { setMapPosition(item.lat, item.lng); onClose() }}>
                                <img src={Marker} alt=""/>
                                <div>
                                    <h5>{item.name}</h5>
                                    <p>{item.address}</p>
                                </div>
                            </li>
                        )
                    }) }
                </ul>
            </div>
        </div>
    )
}

function Map() {
    const [openLocations, setOpenLocations] = useState(false)
    const [mp, setMp] = useState(null)
    let map = null, markers = []

    useEffect(() => {
        initMap()
        //eslint-disable-next-line
    }, [])

    const initMap = () => {
        L.mapbox.accessToken =
          "pk.eyJ1IjoidGR5LWFsdGVydGVybmF0aXZlIiwiYSI6ImNsdHp0ODNraDAzNGkyaXBybWI0MGIwamYifQ.V4YJuRvTRKte1Om6XrdRaw"
    
        map = new L.mapbox.Map("map", "mapbox.streets", { zoomControl: false }).setView([52.0931064, 19.5752418], 6)
        setMp(map)
    
        map.on("ready", () => {
          getMarkers()
          if (navigator.geolocation) {
            map.locate()
          }

        //   let supportsTouch = "ontouchstart" in window || navigator.msMaxTouchPoints
    
          map.dragging.enable()
    
        //   if (supportsTouch) {
        //     map.dragging.disable()
        //   }
        })
    
        map.on("locationfound", (e) => {
          map.setView([e.latlng.lat, e.latlng.lng], 12)
          //eslint-disable-next-line
          let popup = L.popup()
            .setLatLng([e.latlng.lat, e.latlng.lng])
            .setContent("<p>Twoja lokalizacja</p>")
            .openOn(map)
        })
    
        map.on("locationerror", () => {
          console.log("Nie udało się znaleźć lokalizacji")
        })
      }

    const getMarkers = () => {
        showMarkers(places.filter(item => !item.hidden))
    }

    const setMapPosition = (lat, lng) => {
        mp.setView([lat, lng], 18)
    }

    const showMarkers = (data) => {
        if (markers) map.removeLayer(markers)
        markers = []
    
        let markerOptions = {
          iconUrl: Location,
          iconSize: [68, 68],
          iconAnchor: [34, 68],
          popupAnchor: [0, -68],
          className: "dot",
        }
    
        let clusterOptions = {
          polygonOptions: {
            color: "rgba(68,73,78,0.25)",
            weight: 2,
            opacity: 1,
            fillOpacity: 0.3,
          },
          showCoverageOnHover: false,
          iconCreateFunction: function (cluster) {
            let count = cluster.getChildCount()
            let digits = (count + "").length
    
            return new L.divIcon({
              html: count,
              className: "cluster digits-" + digits,
              iconSize: null,
            })
          },
        }
    
        markers = new L.MarkerClusterGroup(clusterOptions)
    
        for (let i = 0; i < data.length; i++) {
          let place = data[i]
          let title = place.name
          let marker
    
          if (title.toLowerCase() !== "tabak") {
            marker = L.marker(new L.LatLng(place.lat, place.lng), {
              title: title,
            })
    
            marker.setIcon(L.icon(markerOptions))
    
            let contentString =
              "<div>" +
              "<b>" +
              data[i].name +
              "</b><br/>" +
              data[i].address +
              "<br/><a href='https://www.google.com/maps/dir//" +
              data[i].lat +
              "," +
              data[i].lng +
              "' target='_blank'>Wskazówki dojazdu</a>" +
              "</div>"
    
            let popup = L.popup().setContent(contentString)
    
            marker.bindPopup(popup)
    
            marker.on("popupopen", () => {
              if (marker._icon) {
                marker._icon.classList.add("marker-active")
              }
            })
    
            marker.on("popupclose", () => {
              if (marker._icon) {
                marker._icon.classList.remove("marker-active")
              }
            })
    
            markers.addLayer(marker)
          }
        }
    
        map.addLayer(markers)
      }

  return (
    <div className="map-page">
      <div className='map' id="map"></div>
      { openLocations ? <LocationsList setMapPosition={setMapPosition} onClose={() => { setOpenLocations(false) }}/> : null }
      <button className='list-button' onClick={() => { setOpenLocations(!openLocations) }}><img src={List} alt=""/></button>
    </div>
  );
}

export default Map;
