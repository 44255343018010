import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import Modal from 'react-modal';
import { getUserDetails, useUserDispatch } from '../../UserContext';
import Button from '../Button/Button';
import Exit from "./img/exit.svg"
import ErrorIcon from "./img/error.svg"
import { errorDefinitions, customActions } from "./definitions"
import _ from "lodash"
import "./style.scss"
import Limit from "./img/ic_limit.svg"

export function showErrorPopup(data, code) {
  if(data && code) data.code = code
  if(!data) {
    data = translateError()
  }
  window.dispatchEvent(new CustomEvent("SHOW_ERROR_POPUP", { detail: data }), true);
}

export function closeErrorPopup() {
  window.dispatchEvent(new CustomEvent("CLOSE_ERROR_POPUP", {}), true);
}

export const translateError = (error) => {
  let errorDetails = {}

  if(error) {
    errorDetails = _.filter(errorDefinitions, { ident: error })[0] || _.filter(errorDefinitions, { ident: "generic" })[0]
  } else {
    errorDetails = _.filter(errorDefinitions, { ident: "generic" })[0]
  }

  return errorDetails
};

export default function ModalComponent({ modalClass = "" }) {
  const [isOpen, setOpen] = useState(false)
  const [error, setErrorDetails] = useState({})
  const dispatch = useUserDispatch()
  const [limitCount, setLimitCount] = useState(parseInt(localStorage.getItem("limitCount")) || 0)

  Modal.setAppElement('#root')

  useEffect(() => {
    window.addEventListener("SHOW_ERROR_POPUP", (data) => {
      const errorDetails = data.detail || {}
      setErrorDetails(errorDetails)
      setOpen(true)
    })

    window.addEventListener("CLOSE_ERROR_POPUP", (data) => {
      setOpen(false)
    })
  }, [])

  return (
    <>
    { error.type === "small_popup_random" ? <Modal isOpen={isOpen} className={"modal leave-modal " + modalClass} overlayClassName={"overlay leave-modal " + modalClass}>
      <div className="leave-modal-inner">
        { !error.disableClose && <div className="button-wrapper">
          <button className='close-button'>
            <img src={Exit} alt="" onClick={ () => { 
              setOpen(false)
              localStorage.setItem("limitCount", limitCount+1)
              setTimeout(() => { setLimitCount(limitCount+1) }, 100) } } />
          </button>
        </div> }
          <div className="leave-top">
            <img src={Limit} alt=""/>
          </div>
          <div className='leave-content'>
            <h3 style={{ width: "90%", margin: "0 auto", maxWidth: "420px" }}>{ error.randomCopy[limitCount%5].title }</h3>
            <p style={{ width: "90%", margin: "16px auto", maxWidth: "420px" }} dangerouslySetInnerHTML={{ __html: error.randomCopy[limitCount%5].desc }}></p>
            { error.returnUrl ? <Button href={ error.returnUrl } action={ () => { 
              setOpen(false)
              localStorage.setItem("limitCount", limitCount+1)
              setTimeout(() => { setLimitCount(limitCount+1) }, 100) 
            } }>{ error.button }</Button> : <Button action={ () => {
            if(error.updateUserData) {
              getUserDetails(dispatch)
            }
            if(error.customAction && customActions[error.customAction]) {
              customActions[error.customAction]()
            }
            setOpen(false)
            localStorage.setItem("limitCount", limitCount+1)
            setTimeout(() => { setLimitCount(limitCount+1) }, 100)
          } }>{ error.button }</Button> }
          </div>
        </div>
  </Modal> : <Modal isOpen={isOpen} className={"error-modal " + modalClass} overlayClassName={"error-overlay " + modalClass}>
    <>
      { !error.disableClose && <div className="button-wrapper">
        <button className='close-button'>
          <img src={Exit} alt="" onClick={ () => { setOpen(false) } } />
        </button>
      </div> }
      <div className="content">
        <h2 dangerouslySetInnerHTML={{ __html: error.title }}></h2>
        <div className='error-description' dangerouslySetInnerHTML={{ __html: error.desc }}></div>
        <img src={ ErrorIcon } alt="Error icon"/>
        { error.code && <div className="error-code">
          <h4>Kod błędu</h4>
          <p>{ error.code }</p>
        </div> }
        { error.returnUrl ? <Button href={ error.returnUrl } target={error.target} action={ () => { setOpen(false) } }>{ error.button }</Button> : <Button action={ () => {
          if(error.updateUserData) {
            getUserDetails(dispatch)
          }
          if(error.customAction && customActions[error.customAction]) {
            customActions[error.customAction]()
          }
          if(error.logout) {
            sessionStorage.removeItem("token")
            sessionStorage.removeItem("currentUser")
            sessionStorage.removeItem("accountDetails")
          }
          setOpen(false)
        } }>{ error.button }</Button> }
      </div>
    </>
  </Modal> }
  </>
  )
}
