// import { clearUrlParams } from "../UrlParamsHelper";

import { showErrorPopup, translateError } from "../components/ErrorHandling";
import { removeUrlParam } from "../UrlParamsHelper";

export async function getAccountDetails(dispatch) {
  try {
    dispatch({ type: 'ACCOUNT_DETAILS' });
    const params = {
      "ar": true
    }

    let response = await fetch(process.env.REACT_APP_API_URL + "/consumer/stats", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))?.uuid || ""
            },
            body: JSON.stringify(params)
        }
    );

    let res = await response.json();
 
    if (res.status.success) {
      const data = {
        zonePlus: res.data.ar
      }

      dispatch({ type: 'ACCOUNT_DETAILS_SUCCESS', payload: data });
      sessionStorage.setItem('accountDetails', JSON.stringify(data));
      return data
    } else {
      showErrorPopup(translateError(res.data.error), res.meta.ts)
    }
 
    dispatch({ type: 'ACCOUNT_DETAILS_ERROR', error: res.data.error });
    return;
  } catch (error) {
    showErrorPopup(translateError("generic"))
    dispatch({ type: 'ACCOUNT_DETAILS_ERROR', error: error });
  }
}

export async function getUserDetails(dispatch) {
  try {
    dispatch({ type: 'USER_DETAILS' });

    let response = await fetch(process.env.REACT_APP_API_URL + "/consumer/details", {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))?.uuid || ""
            }
        }
    );

    let res = await response.json();
 
    if (res.status.success) {
      dispatch({ type: 'USER_DETAILS_SUCCESS', payload: res.data });
      if(res.data?.consumer?.consentAlterzoneParticipate) {
        getAccountDetails(dispatch)
      }
      sessionStorage.setItem('currentUser', JSON.stringify(res.data));
      return res.data
    } else {
      showErrorPopup(translateError(res.data.error), res.meta.ts)
    }
 
    dispatch({ type: 'USER_DETAILS_ERROR', error: res.data.error });
    return;
  } catch (error) {
    showErrorPopup(translateError("generic"))
    dispatch({ type: 'USER_DETAILS_ERROR', error: error });
  }
}

export async function login(dispatch, payload) {
  try {
    dispatch({ type: 'LOGIN' });

    let response = await fetch(process.env.REACT_APP_API_URL + "/consumer/login", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        }
    );

    let res = await response.json();
 
    if (res.status.success) {
      dispatch({ type: 'LOGIN_SUCCESS', payload: res.data });
      sessionStorage.setItem('token', JSON.stringify(res.data.token));
      if(localStorage.getItem("rememberMe")) {
        localStorage.setItem("refreshToken", JSON.stringify(res.data.token))
      }
      getUserDetails(dispatch)
      return res.data
    } else {
      showErrorPopup(translateError(res.data.error), res.meta.ts)
    }
 
    dispatch({ type: 'LOGIN_ERROR', error: res.data.error });
    return;
  } catch (error) {
    showErrorPopup(translateError("generic"))
    dispatch({ type: 'LOGIN_ERROR', error: error });
  }
}

export async function loginByToken(dispatch, token) {
  try {
    const payload = {
      withToken: true
    }

    dispatch({ type: 'LOGIN' });

    let response = await fetch(process.env.REACT_APP_API_URL + "/consumer/details", {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json',
              Authorization: "Bearer " + token,
            },
            body: JSON.stringify(payload)
        }
    );

    let res = await response.json();
 
    if (res.status.success) {
      console.log(res.status)
      dispatch({ type: 'LOGIN_SUCCESS', payload: res.data });
      sessionStorage.setItem('token', JSON.stringify(res.data.token));
      // if(localStorage.getItem("rememberMe")) {
      //   localStorage.setItem("refreshToken", JSON.stringify(res.data.token))
      // }
      dispatch({ type: 'USER_DETAILS_SUCCESS', payload: res.data });
      if(res.data?.consumer?.consentAlterzoneParticipate) {
        getAccountDetails(dispatch)
      }
      sessionStorage.setItem('currentUser', JSON.stringify(res.data));
      removeUrlParam("token")
      return res.data
    } else {
      // showErrorPopup(translateError(res.data.error), res.meta.ts)
      document.location.href = process.env.REACT_APP_GLOPLUS_URL
    }
 
    dispatch({ type: 'LOGIN_ERROR', error: res.data.error });
    return;
  } catch (error) {
    showErrorPopup(translateError("generic"))
    dispatch({ type: 'LOGIN_ERROR', error: error });
  }
}

export async function refreshToken(dispatch, token) {
  try {
    dispatch({ type: 'LOGIN' });

    let response = await fetch(process.env.REACT_APP_API_URL + "/consumer/token-refresh", {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json',
              Authorization: "Bearer " + JSON.parse(token)?.uuid,
            },
            body: null
        }
    );

    let res = await response.json();
 
    if (res.status.success) {
      dispatch({ type: 'REFRESH_SUCCESS', payload: res.data });
      sessionStorage.setItem('token', JSON.stringify(res.data.token));
      loginByToken(dispatch, res.data.token.uuid)
      return res.data
    } else {
      localStorage.removeItem("refreshToken")
      localStorage.removeItem("rememberMe")
    }
 
    dispatch({ type: 'REFRESH_ERROR', error: res.data.error });
    return;
  } catch (error) {
    showErrorPopup(translateError("generic"))
    dispatch({ type: 'REFRESH_ERROR', error: error });
  }
}

export async function logout(dispatch, payload) {
  try {
    dispatch({ type: 'LOGOUT' });

    let response = await fetch(process.env.REACT_APP_API_URL + "/consumer/logout", {
            method: 'POST',
            headers: { 
              'Content-Type': 'application/json',
              Authorization: "Bearer " + JSON.parse(sessionStorage.getItem("token"))?.uuid || ""
            },
            body: JSON.stringify(payload)
        }
    );

    let res = await response.json();
 
    if (res.status.success) {
      dispatch({ type: 'LOGOUT_SUCCESS', payload: res.data });
      sessionStorage.removeItem('currentUser');
      sessionStorage.removeItem('accountDetails');
      sessionStorage.removeItem('token');
      localStorage.removeItem("rememberMe")
      localStorage.removeItem("token")
      removeUrlParam("token")
      document.location.href = process.env.REACT_APP_GLOPLUS_URL+"/aktywnosci/zone-plus"+(process.env.REACT_APP_ENV === "dev" ? `?arReturnUrl=${window.origin}` : "")
      // clearUrlParams()
      return res.data
    } else {
      document.location.href = process.env.REACT_APP_GLOPLUS_URL+"/aktywnosci/zone-plus"+(process.env.REACT_APP_ENV === "dev" ? `?arReturnUrl=${window.origin}` : "")
      // showErrorPopup(translateError(res.data.error), res.meta.ts)
    }

    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('accountDetails');
    sessionStorage.removeItem('token');
    removeUrlParam("token")
    // clearUrlParams()
    dispatch({ type: 'LOGOUT_ERROR', error: res.data.error });
    return;
  } catch (error) {
    // showErrorPopup(translateError("generic"))
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('accountDetails');
    sessionStorage.removeItem('token');
    removeUrlParam("token")
    document.location.href = process.env.REACT_APP_GLOPLUS_URL+"/aktywnosci/zone-plus"+(process.env.REACT_APP_ENV === "dev" ? `?arReturnUrl=${window.origin}` : "")
    // clearUrlParams()
    dispatch({ type: 'LOGOUT_ERROR', error: error });
  }
}